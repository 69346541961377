import { reportError } from '@lib/bugsnag';
import { LOCALES, STATIC_TRANSLATIONS } from 'app/constants';
import { TranslationType } from '@lib/translations';
import { SiteLocale } from '@types';

const flattenObj = (obj: Record<string, any>) => {
  let result: Record<string, string> = {};

  for (const i in obj) {
    if (typeof obj[i] === 'object' && !Array.isArray(obj[i])) {
      const temp = flattenObj(obj[i]);
      for (const j in temp) {
        if (typeof j === 'string') {
          result[i + '.' + j] = temp[j];
        }
      }
    } else if (typeof i === 'string') {
      result[i] = obj[i];
    }
  }
  return result;
};

const interpolate = (
  s: string,
  args?: {
    [key: string]: string | number;
  },
) =>
  !args
    ? s
    : Object.entries(args).reduce(
        (acc, [key, value]) =>
          `${acc.replace(new RegExp(`{{s*${key}s*}}`, 'g'), `${value}`)}`,
        s,
      );

let translations = Object.fromEntries(
  Object.values(SiteLocale).map((l) => [l, flattenObj(STATIC_TRANSLATIONS[l])]),
);

function updateTranslations() {
  return fetch(`${process.env.NEXT_PUBLIC_SITE_URL}/api/translations`, {
    next: { revalidate: 5 * 60 }, // revalidate every 5 minutes
  })
    .then(async (r) => {
      if (r.ok) {
        const fetchedTranslations = (await r.json()) as
          | TranslationType
          | { error: any };

        if (!fetchedTranslations.error) {
          Object.entries(fetchedTranslations).forEach(
            ([key, translationObject]) => {
              LOCALES.forEach((loc) => {
                const parsedKey = key.replaceAll('__', '.');
                translations[loc][parsedKey] = translationObject[loc];
              });
            },
          );
        }
      }
    })
    .catch(async (e) =>
      // Should only really happen if there is an error with Next internal API.
      reportError(
        new Error('Failed to fetch translations from Next API. ' + e.message),
      ),
    );
}

export function getTranslations(locale: SiteLocale) {
  updateTranslations();

  return (key: string, args?: Record<string, string | number>) => {
    if (
      // if no dynamic translation is found
      !translations[locale]?.[key] &&
      // and the dynamic translations are previously set
      Object.keys(translations[locale]).length >
        Object.keys(STATIC_TRANSLATIONS[locale]).length &&
      // and currently in production
      process.env.NODE_ENV === 'production'
    ) {
      // let us know in bugsnag
      reportError(
        new Error(`missing translation for key: ${key} in locale ${locale}`),
      );
    }
    return interpolate(translations[locale][key] || key, args);
  };
}
